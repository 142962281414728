////////////////////////////////////////////
// Colors
////////////////////////////////////////////

// SASS style sheet *!
// Palette color codes *!
// Palette URL: http://paletton.com/#uid=31m1v0kaVz84jP27qHbeJtFiHpX *!
//palette-Ocre

// My brown palette
$color-primary-0: rgba(255, 251, 230, 0.29); //Light
//$color-primary-1: rgba(255, 251, 230, 0.66);
$color-primary-1: rgb(255, 251, 230);
$color-primary-2: rgba(247, 229, 161, 0.45);
$color-primary-3: rgba(172, 137, 87, 0.56);// Main Primary color */
$color-primary-4: rgba(128, 97, 52, 0.67);
$color-primary-9: rgba(49, 34, 11, 1); // Dark

//$color-primary-0: rgba(209, 247, 177, 0.05);
//$color-primary-1: rgba(209, 247, 177, 0.3);
//$color-primary-2: rgba(209, 247, 177, 0.45);// Main Primary color */
//$color-primary-3: rgba(209, 247, 177, 0.6);
//$color-primary-4: rgba(209, 247, 177, 0.8);
//$color-primary-9: rgba(209, 247, 177, 0.99);
//
$color-secondary-0: #000000;
$color-secondary-1: #ff3139;
$color-secondary-2: #3543ff;
$color-secondary-3: #ffa93d;
$color-secondary-4: #53e049;
$color-secondary-5: #3c8c31;
$color-secondary-6: #FFFFE9;
$color-secondary-7: rgba(184, 150, 66, 0.9);

// Definitions des couleurs utilisées par _variables
// NE PAS UTILISER DE FONCTIONS DANS CE FICHIER
$my-body-background:            $color-primary-1;
$my-body-color:                 $color-primary-9;
$my-link-color:                 $color-secondary-5;
$my-link-decoration:            none ;
$my-link-hover-color:           $color-primary-9;
$my-link-hover-decoration:      underline;

$theme-colors: (
    "primary":    $color-primary-3,
    "secondary":  $color-primary-2,
    "success":    $color-secondary-4,
    "info":       $color-secondary-2,
    "warning":    $color-secondary-3,
    "danger":     $color-secondary-1,
    "light":      $color-primary-1,
    "dark":       $color-primary-9,
    'myPrimary1': $color-primary-1,
    'myPrimary2': $color-primary-2,
    'myPrimary3': $color-primary-3,
    'myPrimary4': $color-primary-4,
    'myFondOpaq': $color-secondary-6,
    'myPreColor': $color-secondary-4,
    'myCodeColor':$color-secondary-1,
);


// Color system

$white:    #fff !default;
$gray-100: #f8f9fa !default;
$gray-200: #e9ecef !default;
$gray-300: #dee2e6 !default;
$gray-400: #ced4da !default;
$gray-500: #adb5bd !default;
$gray-600: #6c757d !default;
$gray-700: #495057 !default;
$gray-800: #343a40 !default;
$gray-900: #212529 !default;
$black:    #000 !default;

$grays: () !default;
// stylelint-disable-next-line scss/dollar-variable-default
$grays: map-merge(
                (
                        "100": $gray-100,
                        "200": $gray-200,
                        "300": $gray-300,
                        "400": $gray-400,
                        "500": $gray-500,
                        "600": $gray-600,
                        "700": $gray-700,
                        "800": $gray-800,
                        "900": $gray-900
                ),
                $grays
);