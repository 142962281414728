//<editor-fold desc="----------- Jquery-ui Tooltip">
@import '_variables.scss';



[data-title]:hover:after {
  opacity: 1;
  transition: all 0.1s ease 0.5s;
  visibility: visible;
}
[data-title]:after {
  content: attr(data-title);
  color: white;
  white-space: normal;
  border-radius: 10%;
  -moz-border-radius: 5px;
  -webkit-border-radius: 5px;
  -moz-box-shadow: 0px 0px 4px #222;
  -webkit-box-shadow: 0px 0px 4px #222;
  box-shadow: 0px 0px 4px #222;
  background-color: black;
  opacity: 0;
  z-index: 99999;
  visibility: hidden;
  position: absolute;
  padding: 4px 4px 4px 8px;
  margin-left: -5px;
  top: 100%; /* At the bottom of the tooltip */
  left: 50%;
  min-width: auto;
}

[data-title] {
  position: relative;
}

// Fin Jquery-ui Tooltip </editor-fold>