////////////////////////////////////////////
// Import des css & fonts -> Compilation
////////////////////////////////////////////

//$fa-font-path: "../webfonts";
//@import "../fonts/font-awesome/fontawesome.scss";
//@import "../fonts/font-awesome/solid.scss";
//@import "../fonts/font-awesome/regular.scss";
//@import "../fonts/font-awesome/brands.scss";
//
@import '_variables.scss';
@import '_functions.scss';
@import "../../node_modules/bootstrap/scss/bootstrap";
@import '_mixins.scss';
@import '_mystyles.scss';

