// =============================================================================
//	Mixins and Variables
// =============================================================================
//
@import '_colors.scss';
@mixin ul-nostyle {
  list-style: none;
}
//
//@mixin code-php {
//  background-color: gray(20);
//  color: red;
//}


@mixin degradeHorizTresClair {
  color:  theme-color("dark");
  background:  theme-color("light");
  background: -moz-linear-gradient( theme-color("myPrimary1") 0%,  theme-color("myPrimary2") 100%);
  background: -webkit-gradient(linear, left top, left bottom, color-stop(0%,  theme-color("myPrimary1")), color-stop(100%,  theme-color("myPrimary2")));
  background: -webkit-linear-gradient( theme-color("myPrimary1") 0%,  theme-color("myPrimary2") 100%);
  background: linear-gradient( theme-color("myPrimary1") 0%,  theme-color("myPrimary2") 100%);
}

@mixin degradeHorizClair{
  color:  theme-color("dark");
  background:  theme-color("myPrimary1");
  background: -moz-linear-gradient( theme-color("myPrimary2") 0%,  theme-color("myPrimary3") 100%);
  background: -webkit-gradient(linear, left top, left bottom, color-stop(0%,  theme-color("myPrimary2")), color-stop(100%,  theme-color("myPrimary3")));
  background: -webkit-linear-gradient( theme-color("myPrimary2") 0%,  theme-color("myPrimary3") 100%);
  background: linear-gradient( theme-color("myPrimary2") 0%,  theme-color("myPrimary3") 100%);
}

@mixin degradeHorizMedium{
  opacity:1;
  color:  theme-color("dark");
  background:  theme-color("myPrimary2");
  background: -moz-linear-gradient( theme-color("myPrimary3") 0%,  theme-color("myPrimary4") 100%);
  background: -webkit-gradient(linear, left top, left bottom, color-stop(0%,  theme-color("myPrimary3")), color-stop(100%,  theme-color("myPrimary4")));
  background: -webkit-linear-gradient( theme-color("myPrimary3") 0%,  theme-color("myPrimary4") 100%);
  background: linear-gradient( theme-color("myPrimary3") 0%,  theme-color("myPrimary4") 100%);
}
@mixin degradeHorizDark{
  color:  theme-color("light");
  background:  theme-color("myPrimary3");
  background: -moz-linear-gradient( theme-color("myPrimary3") 0%,  theme-color("dark") 100%);
  background: -webkit-gradient(linear, left top, left bottom, color-stop(0%,  theme-color("myPrimary3")), color-stop(100%,  theme-color("dark")));
  background: -webkit-linear-gradient( theme-color("myPrimary3") 0%,  theme-color("dark") 100%);
  background: linear-gradient( theme-color("myPrimary3") 0%,  theme-color("dark") 100%);
}




@mixin myBorder {
  border: 1px solid  theme-color("myPrimary4");
}

@mixin myBorderSimple {
  border: 1px solid  theme-color("dark");
  -webkit-border-radius: 6px;
  -moz-border-radius: 6px;
  border-radius: 6px;
}

@mixin myBorderSimpleGrise {
  border: 1px solid  $gray-500;
  -webkit-border-radius: 6px;
  -moz-border-radius: 6px;
  border-radius: 6px;
}

@mixin myBorderOmbre {
  @include myBorderSimple;
  box-shadow: 3px 3px 3px gray("600");
}

@mixin myNoBorder {
  border-radius: 0;
  box-shadow: none;
  border: none;
}

@mixin margePaddingGrand{
  padding: 0.3rem;
  margin: 0.3rem;
}

@mixin margePaddingMoyen {
  padding: 0.3rem;
  margin: 0.3rem;
}

@mixin margePaddingPetit {
  padding: 0.1rem;
  margin: 0.1rem;
}

@mixin margePaddingSans {
  padding: 0;
  margin: 0;
}
@mixin textFullCentre{
  text-align: center;
  align-self: center;
}
