////////////////////////////////////////////
// Overwrite des variables SCSS
////////////////////////////////////////////
@import '_colors.scss';

/*body par defaut*/
$body-bg:                   $my-body-background;
$body-color:                $my-body-color;
$link-color:                $my-link-color;
$link-decoration:           $my-link-decoration;
$link-hover-color:          $my-link-hover-color;
$link-hover-decoration:     $my-link-hover-decoration;

// Bootstrap default
/*
$body-bg:                   $white ;
$body-color:                $gray-900 ;

$link-color:                theme-color("primary") ;
$link-decoration:           none ;
$link-hover-color:          darken($link-color, 15%) ;
$link-hover-decoration:     underline ;

$grid-breakpoints: (
        xs: 0,
        sm: 576px,
        md: 768px,
        lg: 992px,
        xl: 1200px
) ;

$container-max-widths: (
  sm: 540px,
  md: 720px,
  lg: 960px,
  xl: 1140px
) ;
$font-family-sans-serif:      -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji" ;
$font-family-monospace:       SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace ;
*/