//<editor-fold desc="----------- Définition Grid">

/*
------------------------------------------------------------------
| Logo    |               TtrePage              |Menulogin       |
| Horloge | Sentier                             |MnuLoginSuite   |
------------------------------------------------------------------
| MenuG   |               Content               |MenuDroite      |
------------------------------------------------------------------
                         Footer
*/

body {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  grid-template-rows: auto;
  // Voir _variables.scss pour la présentation
}

.zone-head {
  grid-column: 1/ 7;
  grid-row: 1;
  display: grid;
  grid-template-columns: repeat(6, 1fr);
}

.zone-logo {
  grid-column: 1;
  grid-row: 1;
}

.zone-titre-page {
  //grid-area: ti;
  grid-column: 2/ span 4;
  grid-row: 1;
}

.zone-menu-login {
  //grid-area: nt;
  grid-column: -2;
  grid-row: 1/span 2;
}

.zone-horloge {
  // grid-area: hr;
  grid-column: 1 / -6;
  grid-row: 2;
}

.zone-sentier {
  //grid-area: se;
  grid-column: 2 / span 4;
  grid-row: 2;

}

.zone-menu-gauche {
  //grid-area: mg;
  grid-column: 1;
  grid-row: 4;
}

.zone-content {
  //grid-area: cn;
  grid-column: 2 / span 4;
  grid-row: 4;
}

.zone-menu-droite {
  //grid-area: md;
  grid-column: -2;
  grid-row: 4;
}

.zone-footer {
  //grid-area: ft;
  grid-column: 1 / -1;
  grid-row: 5;
}

// Fin definition Grid </editor-fold>

//<editor-fold desc="----------- Presentations zones Grid">
.head {
  @include margePaddingPetit;
  @include myBorderSimple;
}

.logo {
  //height: 45px;
  @include textFullCentre;
}

.titre-page {
  font-size: 250%;
  @include textFullCentre;
}

.menu-login {
  @include margePaddingMoyen;
}

.horloge {
  @include textFullCentre;
  .horloge-date {
    font-size: 90%;
  }
  .horloge-time {
    font-size: 95%;
  }
}

.sentier {
  //@include margePaddingSans;
  //@include myBorderSimple;
}

.menu-gauche {
  @include margePaddingPetit;
  @include myBorderSimple;
}

.content {
  @include margePaddingPetit;
  @include myBorderSimple;
}

.menu-droite {
  text-align: right;
  @include myBorderSimple;
  @include margePaddingPetit;
}

.footer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-self: center;
  border-top: solid black 1px;
  @include margePaddingPetit;
  .contact {

  }
  .copyright {

  }
  .plan-site {

  }
}

// Fin Presentations zones Grid </editor-fold>

//<editor-fold desc="----------- Généralités">
// Gestion collapse/expand du menu Link
.listLink {
  display: none;
  height: auto;
}

.show {
  display: none;
}

.hide:target + .show {
  display: inline;
}

.hide:target {
  display: none;
}

.hide:target ~ .listLink {
  display: inline;
}

.texte-full-center {
  @include textFullCentre;
}

// Fin Presentations zones Grid </editor-fold>

//<editor-fold desc="----------- Structure">

.myFlex {
  display: flex;
  flex-direction: row; // axe principal (main axis)
  justify-content: center; // positionnment le long de l’axe principal sur la ligne courante (main axis)
  //align-items: first  ; // positionnment le long de l’axe secondaire
  align-self: center; // définition alignement sur l’axe secondaire d'un objet au sein de son conteneur. Surcharge le comportement par défaut de align-items.
  flex-wrap: wrap; // disposition sur une seule ligne ou retour automatique.
  @include myBorderSimple;
}

.myFlexHorizontal {
  display: flex;
  flex-direction: row; // axe principal (main axis)
  justify-content: space-around; // positionnment le long de l’axe principal sur la ligne courante (main axis)
  align-items: center; // positionnment le long de l’axe secondaire
  align-self: center; // définition alignement sur l’axe secondaire d'un objet au sein de son conteneur. Surcharge le comportement par défaut de align-items.
  flex-wrap: wrap; // disposition sur une seule ligne ou retour automatique.
}

.myFlexVertical {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-self: center;
  flex-wrap: wrap;
  @include myBorderSimple;
}

.myCard {
  @include myBorderOmbre;
  background: theme-color("myPrimary1");
  margin: 1em;
  padding: 0.5em;
  width: 110px;
  min-width: 0;
}


.card {
  background: theme-color("myPrimary1");
}

.myCardHorizontal {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  flex-wrap: wrap;
  justify-content: space-around;
  list-style: none;
}

.myCardVertical {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex-wrap: wrap;
  justify-content: space-around;
  //text-align: center;
  list-style: none;
}

.myUl {
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
  justify-content: left;
  list-style: none;
  padding: 0px;
  margin: 0px;
}

// Fin Structure </editor-fold>

//<editor-fold desc="----------- Presentations codes Html">


* {
  box-sizing: border-box;
}

html {
  line-height: 1.15;
}

body {

}

p {
  margin: 0;
}

.form-control {
  //width: auto;
}

h1 {
  margin-bottom: 0.5rem;
  margin-top: 0.5rem;
  margin-left: 0rem;
  font-weight: bolder;
  line-height: 1.5;
}

h2 {
  margin-bottom: 0.4rem;
  margin-top: 0.4rem;
  margin-left: 1rem;
  font-weight: bolder;
  line-height: 1.4;
}

h3 {
  margin-bottom: 0.3rem;
  margin-top: 0.3rem;
  margin-left: 2rem;
  font-weight: bolder;
  line-height: 1.3;
}

h4 {
  margin-bottom: 0.2rem;
  margin-top: 0.2rem;
  margin-left: 3rem;
  font-weight: bolder;
  line-height: 1.2;
}

h5 {
  margin-bottom: 0.1rem;
  margin-top: 0.1rem;
  margin-left: 4rem;
  font-weight: bolder;
  line-height: 1.1;
}

h6 {
  margin-bottom: 0.0rem;
  margin-top: 0.0rem;
  margin-left: 5rem;
  font-weight: bold;
  line-height: 1.0;
}

h7 {
  margin-bottom: 0.0rem;
  margin-top: 0.0rem;
  margin-left: 6rem;
  font-weight: bold;
  line-height: 1.0;
}

button {
  @include degradeHorizClair;
  @include myBorderOmbre;
  @include textFullCentre;
  @include margePaddingPetit;
  width: 100%;
}

button:hover {
  @include degradeHorizDark;
}

table.center {
  margin-left:auto;
  margin-right:auto;
}

caption {
  caption-side: bottom;
  color: gray('600');
  font-style: italic;
  font-size: smaller;
}

figure {
  font-size: smaller;
  text-indent: 0;
}

figcaption {
  caption-side: bottom;
  color: gray('600');
  font-size: smaller;
  font-style: italic;
  margin-top: 0;
  margin-bottom: .5em;
  margin-left: 0.1em;
  margin-right: 0.1em;
}

blockquote {
  font-style: normal;
  font-size: 100%;
  margin-left: 32px;
  font-family: Consolas, "Times New Roman", Verdana;
  border-left: 4px solid #CCC;
  padding-left: 8px;
}

tt {
  font-size: 110%;
  font-weight: bold;
  font-family: Consolas, "courier new", monospace, sans-serif;
}

kbd {
  font-size: 105%;
  /*border: 1px solid black;*/
  text-underline: dotted;
  font-family: Consolas, "courier new", monospace, sans-serif;
}

q {
  quotes: "«" "»";
  font-style: italic;
  //font-family: Consolas, "courier new", monospace, sans-serif;
}

q::before {
  content: open-quote;
}

q:after {
  content: close-quote;
}

dt {
  padding-top: .5em;
  border-top: 1px solid theme-color("dark") !important;
}

dd {
  padding-top: .5em;
  border-top: 1px solid theme-color("dark") !important;
}

code {
  color: theme-color("myCodeColor");
  background-color: gray("400");
  font-family: Consolas, "courier new", monospace, sans-serif;
  font-size: 110%;
}

pre {
  color: theme-color("myPreColor");
  background-color: gray("900");
  font-family: Consolas, "courier new", monospace, sans-serif;
  font-size: 95%;
}

form-group{
  @include myBorderOmbre;
}
label{
  text-align: left;
  font-size: small;
  margin: 0;
  padding: 0;
  display:grid;
}
//label:after{
//  content: ' : ';
//}

// Fin Presentations codes Html </editor-fold>

//<editor-fold desc="----------- Bootstrap">
.form-control {
  background-color: theme-color("myPrimary1");
}

.page-link {
  background-color: theme-color("myPrimary1");
}

.page-item.disabled .page-link {
  background-color: theme-color("myPrimary1");
}

// Fin Bootstrap </editor-fold>

//<editor-fold desc="----------- Partie spécifiques application">

.myBorderOmbre {
  @include myBorderOmbre;
}

.myBorder {
  @include myBorder;
}

.myBorderSimple {
  @include myBorderSimple;
}
.myBorderSimpleGrise {
  @include myBorderSimpleGrise;
}

.myNoBorder {
  @include myNoBorder;
}

.myText-align-full-center {
  @include textFullCentre;
}

.myText-align-right {
  text-align: right;
}

.myText-align-left {
  text-align: left;
}

.myText-align-center {
  text-align: center;
}

.myMarginPad-Petite {
  @include margePaddingPetit
}

.myMarginPad-Moyenne {
  @include margePaddingMoyen
}

.myMarginPad-Grande {
  @include margePaddingGrand
}


.full-width {
  width: 100%;
}

.citation {
  .citation-texte {
    text-align: right;
    font-style: italic;
    @include margePaddingPetit;
  }
  .citation-auteur {
    font-style: italic;
    text-align: right;
    font-weight: bold;
    color: gray("400");
  }
}

.frm_image {
  @include textFullCentre;
  @include margePaddingMoyen;
}

.my-display-1 {
  font-weight: bolder;
  font-size: 1.5em;
}

.my-display-2 {
  font-weight: bolder;
  font-size: 1.4em;
}

.my-display-3 {
  font-weight: bolder;
  font-size: 1.3em;
}

.my-display-4 {
  font-weight: bolder;
  font-size: 1.2em;
}

.my-display-5 {
  font-weight: bolder;
  font-size: 1.1em;
}

.my-display-6 {
  font-weight: bold;
  font-size: 1.05em;
}

.my-display-7 {
  font-weight: bold;
  font-size: 1em;
}

.page-niv0 {
  padding: 0 1em 0 00em;
  font-size: 160%;
}

.page-niv1 {
  padding: 0 1em 0 2em;
  font-size: 140%;
}

.page-niv2 {
  padding: 0 1em 0 4em;
  font-size: 120%;
}

.page-niv3 {
  padding: 0 1em 0 6em;
  font-size: 100%;
}

.page-niv4 {
  padding: 0 1em 0 8em;
  font-size: 100%;
}

.page-niv5 {
  padding: 0 1em 0 10em;
  font-size: 100%;
}

.page-niv6 {
  padding: 0 1em 0 12em;
  font-size: 100%;
}

.page-lib0 {
  padding: 0 1em 0 0em;
}

.page-lib1 {
  padding: 0 1em 0 02em;
}

.page-lib2 {
  padding: 0 1em 0 04em;
}

.page-lib3 {
  padding: 0 1em 0 06em;
}

.page-lib4 {
  padding: 0 1em 0 08em;
}

.page-lib5 {
  padding: 0 1em 0 10em;
}

.page-lib6 {
  padding: 0 1em 0 12em;
}

.my-success{
  background-color: green;
  color: black;
  font-size: 1.1em;
  font-weight: bold;
}

.my-danger{
  background-color: red;
  color: black;
  font-size: 1.1em;
  font-weight: bold;
}

// Fin JQuery-Ui </editor-fold>

//<editor-fold desc="----------- Menu Vertical">
//
#myMenuVertical,
#myMenuVertical ul,
#myMenuVertical li,
#myMenuVertical a {
  margin: 0;
  padding: 0;
  border: 0;
  list-style: none;
  font-weight: normal;
  text-decoration: none;
  line-height: 1em;
  transition: all 0.3s;

}

#myMenuVertical a {
  line-height: 1.3;
}

#myMenuVertical > ul > li {
  @include margePaddingPetit;

}

#myMenuVertical > ul > li:last-child {
  margin: 0;
}

// Menu 1er Niv
#myMenuVertical > ul > li > a {
  font-size: 120%;
  display: block;
  position: relative;
  @include degradeHorizClair;
  @include myBorderSimple;
}

#myMenuVertical > ul > li > a > span {
  display: block;
  border: 1px solid #666666;
  padding: .1rem;
  @include myBorderOmbre;

}

#myMenuVertical > ul > li > a:hover {
  text-decoration: none;
}

#myMenuVertical > ul > li.active {
  border-bottom: none;
}

// Menu 1er Niv Hover
#myMenuVertical > ul > li.active > a {
  @include degradeHorizDark;
  @include myBorderSimple;
}

#myMenuVertical > ul > li.active > a span {
  border: 1px solid theme-color("dark");
}

#myMenuVertical > ul > li.has-sub > a span:after {
  background: url("/build/images/icon_plus.png") 98% center no-repeat;
  //content: '⊕'; // &#8853;
  //text-align: right;
}

#myMenuVertical > ul > li.has-sub.active > a span:after {
  background: url("/build/images/icon_minus.png") 98% center no-repeat;
  //content: '⊖'; //&#8854;
  //text-align: right;
}

/* Sub menu */
#myMenuVertical ul ul {
  display: none;
}

#myMenuVertical ul ul li {
  @include margePaddingPetit;

}

// Menu Sub
#myMenuVertical ul ul a {
  display: block;
  padding: .1em;
  @include degradeHorizClair;
  @include myBorderOmbre;
  font-size: 110%;
}

// Menu Sub Hover
#myMenuVertical ul ul a:hover {
  @include degradeHorizDark;
  @include myBorderOmbre;
}

//////////////////////////////////////////////////////////





// Fin Menu Vertical </editor-fold>

//<editor-fold desc="----------- myMenuHorizontal Horizontal">
#myMenuHorizontal
#myMenuHorizontal ul {
  padding: 0;
  margin: 0;
  list-style: none;
  font-weight: normal;
  text-decoration: none;
  line-height: 1em;
  transition: all 0.3s;
  font-size: 1.3em;

}

#myMenuHorizontal a {
  text-decoration: none;
  display: inline-block;
  @include margePaddingPetit;
  @include degradeHorizClair;
  @include myBorderOmbre;

}

#myMenuHorizontal li {
  /* on place les liens du menu horizontalement */
  margin-right: 1em;
  margin-top: 1em;
  display: inline-block;

}

#myMenuHorizontal ul li {
  /* on enlève le display pour les liens du sous menu */
  display: inherit;
  @include margePaddingMoyen;

}

#myMenuHorizontal ul {
  /* Partie cadre des sous menus */
  position: absolute;
  right: 15px;
  display: none;
  @include myBorderOmbre;
  @include margePaddingPetit;
  background-color: white;

}

#myMenuHorizontal li:hover ul {
  /* Au survol des li du menu on replace les sous menus */
  display: block;

}

// Fin Menu Horizontal </editor-fold>

//<editor-fold desc="----------- Test">
.primary0 {
  background-color: theme-color("light");

}

.primary1 {
  background-color: theme-color("myPrimary1");
}

.primary2 {
  background-color: theme-color("myPrimary2");
}

.primary3 {
  background-color: theme-color("myPrimary3");
}

.primary4 {
  background-color: theme-color("dark");
}

.degradeHorizTresClair {
  @include degradeHorizTresClair
}

.degradeHorizClair {
  @include degradeHorizClair
}

.degradeHorizMedium {
  @include degradeHorizMedium
}

.degradeHorizDark {
  @include degradeHorizDark
  //color: #2F611C;
}

// Fin Test </editor-fold>

